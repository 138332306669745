<template>
  <ul class="flex pagination">
    <li class="pagination-item pagination-first">
      <button
        type="button"
        class="pagination-button"
        @click="onClickFirstPage"
        :disabled="isInFirstPage"
      >
        पहिलो
      </button>
    </li>

    <li class="pagination-item pagination-previous">
      <button
        type="button"
        class="pagination-button"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
      >
        अघिल्लो
      </button>
    </li>

    <!-- Visible Buttons Start -->

    <li v-for="page in pages" :key="page.name" class="pagination-item">
      <button
        type="button"
        :disabled="page.isDisabled"
        @click="onClickPage(page.name)"
        class="pagination-number"
        :class="{ active: isPageActive(page.name) }"
      >
        {{ englishToNepaliNumber(page.name) }}
      </button>
    </li>

    <!-- Visible Buttons End -->
    <li class="pagination-item pagination-next">
      <button
        type="button"
        class="pagination-button"
        dropzone=""
        @click="onClickNextPage"
        :disabled="isInLastPage"
      >
        अर्को
      </button>
    </li>

    <li class="pagination-item pagination-last">
      <button
        type="button"
        class="pagination-button"
        @click="onClickLastPage"
        :disabled="isInLastPage"
      >
        अन्तिम
      </button>
    </li>
  </ul>
</template>

<script>
import { englishToNepaliNumber } from "nepali-number";

export default {
  data() {
    return {
      englishToNepaliNumber,
    };
  },
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];
      let start = this.startPage > 0 ? this.startPage : 1;
      for (
        let i = start;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      if (this.currentPage === this.totalPages || this.totalPages === 0)
        return true;
      else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  list-style-type: none;
  justify-content: center;
  padding: 15px 0;
  &-item {
    display: inline-block;
    height: 36px;
    margin-right: 20px;
    width: 36px;
    &:last-child {
      margin-right: 0;
    }
    button {
      background: transparent;
      cursor: pointer;
      height: 36px;
      &:hover {
        color: $primary;
        font-weight: 700;
      }
    }
  }

  &-first,
  &-previous,
  &-next,
  &-last {
    width: auto;
    button {
      background: transparent;
      color: $neutrals-black;
    }
    &:hover button {
      color: $primary;
      font-weight: normal;
    }
  }
  &-previous {
    align-items: center;
    display: flex;
    &:before {
      background: url(../../assets/ic_sprite.svg) no-repeat 0 0;
      content: "";
      display: block;
      height: 24px;
      filter: brightness(0);
      margin-top: -4px;
      transform: rotate(-180deg);
      width: 24px;
    }
  }
  &-next {
    align-items: center;
    display: flex;
    &:after {
      background: url(../../assets/ic_sprite.svg) no-repeat 0 0;
      content: "";
      display: block;
      height: 24px;
      filter: brightness(0);
      margin-top: -4px;
      width: 24px;
    }
  }
  &-previous:hover,
  &-next:hover {
    &:before,
    &:after {
      filter: none;
    }
  }
  &-button {
    cursor: pointer;
  }
  &-number {
    border-radius: 50%;
    color: $neutrals-black;
    width: 36px;
    &.active {
      background: $primary;
      color: $neutrals-white !important;
      font-weight: 700;
    }
  }
}
</style>
